import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//resources has the translations in different languages if you want to add more languages you can add them here
//en is for English and es is for Spanish
//translation is the key and the value is the translation
//each key must be a translation of the key in the other language

const resources = {
  en: {
    translation: {
      "home-a-1": "$",
      "home-a-2": "Amount in Dollars",
      "home-a-3": "30 Days",
      "home-a-4": "Need to buy USDT? You can get them on Onramp exchanges.",
      "home-a-5": "Here you can find some examples:",
      "home-a-6": "Click here",
      "home-a-7": "You must Sign in to Start",
      "home-a-8": "Profile",
      "home-a-9": "Referrals",
      "home-a-10": "Connecting",
      "home-a-11": "Reconnecting",
      "home-a-12": "Create your wallet/Sign in",
      "home-a-13": "Enter your referral code",
      "home-a-14": "The referral code must have 6 digits.",
      "home-1": "Return to Revenue",
      "home-2":
        "Effortless Passive Income. Choose a plan, we automate everything and you stay in control.",
      "home-3": "It's never been easier.",
      "home-4":
        "Make your money work for you by leveraging the best opportunities in decentralized finance (DeFi) with just two clicks.",
      "home-5": "Moderate",
      "home-6": "Aggressive",
      "home-7": "Fiery",
      "home-8": "Crazy",
      "home-9": "Investment",
      "home-10": "Time",
      "home-11": "Your Balance",
      "home-12": "Choose the plan that best suits your profile.",
      "home-13": "Just choose a plan: the rest works on autopilot.",
      "home-14":
        "Make your money work for you by leveraging the best opportunities in decentralized finance (DeFi) with just two clicks.",
      "home-15": "Get Started",
      "home-16": "It's never been easier.",
      "home-17": "Liquidity Always Available",
      "home-18": "You are always in control of your money.",
      "home-19": "Profit distribution every 24h",
      "home-20": "How does it work?",
      "home-21":
        "We bring you DeFi (Decentralized Finance) opportunities without the technological complexities.",
      "home-22": "Create your account from Google",
      "home-23":
        "Create your account and wallet by simply logging in with Google",
      "home-24": "Deposit Funds.",
      "home-24-a": "From USDT directly into your wallet",
      "home-25": "Choose a Plan",
      "home-26": "Select the plan that suits you best.",
      "home-27": "Earn Passive Income",
      "home-28": "Enjoy watching your money grow effortlessly.",
      "home-29": "Hassle-free",
      "home-30":
        "Withdraw your funds at any time and enjoy the generated profits.",
      "home-31": "Security + Trust.",
      "home-31-a": "Exchange crypto for fiat with a regulated VASP.",
      "home-32": "Your wallet = your cryptos.",
      "home-32-a":
        "We can generate a wallet giving you full control and the power of self-custody.",
      "home-33": "Don't worry about gas fees.",
      "home-33-a":
        "Gas fees are the toll for transactions on the blockchain; everything is automated with no additional cost to you.",
      "home-34": "DeFi Opportunities in Two Clicks.",
      "home-34-a":
        "We provide optional access to four plans with varying risks and returns, covering the best market options in each segment, selected by field experts.",
      "home-35": "Automated Interest Compounding.",
      "home-35-a":
        "Our technology reinvests the generated profits to multiply gains through compound interest.",
      "home-36": "Cash in/out in USDT.",
      "home-36-a":
        "You decide when and where to withdraw your money at any time.",
      "home-37": "Be part of our community",
      "home-38": "Home",
      "home-39": "Legal Notice",
      "home-40": "Terms & Conditions",
      "home-42":
        "Welcome! Return to Revenue is in an early access phase and is only accessible via a referral code for early adopters. We may expand access in the future. For assistance, contact us. Thank you.",
      "home-43": "I agree to the",
      "home-44": "I agree to the",
      "home-45": "Enter Code",
      "home-46": "Enter your referral code.",

      "r2r-b-1": "What is R2R?",
      "r2r-b-2":
        "R2R is an exclusive platform that offers its members various options to participate in decentralized finance (DeFi) through blockchain, facilitating the process with two clicks from your Google account.",
      "r2r-b-3": "How can I start using R2R?",
      "r2r-b-4":
        "Access to R2R is by invitation or referral only. Once you receive an invitation, simply enter the code on the home page to start using the platform",
      "r2r-b-5": "What do I do if I don't have a web3 wallet?",
      "r2r-b-6":
        "R2R is designed to be accessible to everyone. From our fully automated process we create the wallet or digital wallet, we pay the fees and all you have to do is choose the option that best suits your needs/your profile.",
      "r2r-b-7": "How is passive income generated in R2R?",
      "r2r-b-8":
        "Passive income is generated through the liquidity pools we offer on various blockchain protocols and networks. Users can choose the option that best suits their risk profile.",
      "r2r-b-9": "How often is passive income generated?",
      "r2r-b-10":
        "Income is generated continuously. Every 24 hours, we reinvest profits with compound interest to maximize returns.",
      "r2r-b-11": "Is it safe to use R2R?",
      "r2r-b-12":
        "Our smart contracts are audited and we work with the most secure and experienced protocols in the DeFi sector, guaranteeing maximum security.",
      "r2r-b-13": "What commission does R2R charge?",
      "r2r-b-14":
        "The commission is 13% for all management, wallet creation and fee payments within the platform.",
      "r2r-b-15": "Can I withdraw my money at any time?",
      "r2r-b-16":
        "Yes, you will always have full control over your assets. R2R is a platform that facilitates participation in liquidity pools, but you are the owner of your funds at all times",
      "r2r-b-17": "What happens if I get hacked?",
      "r2r-b-18":
        "We do not have access to your money. It is a self-custody service. Each user is responsible for the security of their own assets",
      "r2r-b-19": "How can I get an invitation to R2R?",
      "r2r-b-20":
        "You will only be able to access R2R by invitation using a referral code from an active member.",
      "r2r-b-21": "What types of participation are available in R2R?",
      "r2r-b-22":
        "We offer a variety of participation options in different liquidity pools, covering different blockchain networks and DeFi protocols to diversify your portfolio",
      "r2r-b-23": "What is a liquidity pool?",
      "r2r-b-24":
        "A liquidity pool is a pool of funds locked in a smart contract that are used to facilitate trading on decentralized exchanges (DEX). Pool participants earn rewards in exchange for providing liquidity.",
      "r2r-b-25": "What is blockchain and why is it important for R2R?",
      "r2r-b-26":
        "Blockchain is a decentralized ledger technology that guarantees transparency, security and immutability of transactions. R2R uses blockchain to offer secure and transparent investment options",
      "r2r-b-27": "What benefits do I get from being a member of R2R?",
      "r2r-b-28":
        "As an R2R member, you will have exclusive access to opportunities to participate in DeFi, personalized support, and tools to maximize your passive income efficiently and safely",
      "r2r-b-29": "What should I do if I have problems with my account?",
      "r2r-b-30":
        "You can contact our support team through the platform by sending an email to our customer service",

      "r2r-1": "Dashboard",
      "r2r-2": "My Profile",
      "r2r-3": "Participate",
      "r2r-4": "My Positions",
      "r2r-5": "Referrals",
      "r2r-6": "FAQ",
      "r2r-7": "",
      "r2r-8": "Dashboard",
      "r2r-9": "Available",
      "r2r-10": "Invested",
      "r2r-11": "Referrals",
      "r2r-12": "Add",
      "r2r-13": "Add Balance",
      "r2r-14": "Method",
      "r2r-15": "Available",
      "r2r-16":
        "You can now deposit USDT on the Polygon network into your R2R wallet",
      "r2r-17": "Select",
      "r2r-18": "Add balance to your R2R account",
      "r2r-19": "Send to",
      "r2r-20":
        "Enter the amount you wish to deposit in USDT (Tether) into your R2R account via the Polygon network from any wallet of your choice.",
      "r2r-21": "Copy Address",
      "r2r-22": "Withdraw",
      "r2r-23": "Withdraw Balance",
      "r2r-24":
        "You can now withdraw your USDT on the Polygon network to any external wallet outside of R2R. Make sure to operate on the same network.",
      "r2r-25": "Withdraw balance outside of R2R",
      "r2r-26": "Available",
      "r2r-27":
        "Enter the amount you wish to deposit in USDT (Tether) into your R2R account via the Polygon network from any wallet of your choice.",
      "r2r-28": "Continue",
      "r2r-29": "Deposit",
      "r2r-30": "",
      "r2r-31": "My Positions",
      "r2r-32": "~Daily",
      "r2r-33": "Information panel about your open positions within R2R",
      "r2r-34": "Total Balance",
      "r2r-35":
        "Information panel about the aggregated amount of all your positions",
      "r2r-36": "Total Balance",
      "r2r-37": "Recent Activity",
      "r2r-38":
        "Information panel of your wallet's interaction with R2R: deposits and withdrawals",
      "r2r-39": "",
      "r2r-40": "My Profile",
      "r2r-41": "Customize your account",
      "r2r-42": "No file selected",
      "r2r-43": "Change Avatar",
      "r2r-44": "If you want to customize your nickname",
      "r2r-45": "Balance in your wallet",
      "r2r-46": "Your wallet in R2R | Only you have access to your funds",
      "r2r-47": "Save",
      "r2r-48": "Activate my 2FA",
      "r2r-49": "Export your Private Key",
      "r2r-50": "You must activate your 2FA to export your private key",
      "r2r-51": "",
      "r2r-52": "Participate",
      "r2r-53": "Moderate",
      "r2r-54": "Position with stable coins",
      "r2r-55": "No volatility risks",
      "r2r-56": "year",
      "r2r-57": "Aggressive",
      "r2r-58": "Position with most well-known stable and volatile coins",
      "r2r-59": "Impermanent loss risk*",
      "r2r-60": "APY ~442.14%",
      "r2r-61": "Fiery",
      "r2r-62":
        "Position with stable or only volatile currencies seeking higher performance",
      "r2r-63": "Impermanent loss risk*",
      "r2r-64": "APY ~329.4%",
      "r2r-65": "Crazy",
      "r2r-66":
        "Position with more unknown volatile currencies seeking greater performance in less time",
      "r2r-67": "Impermanent loss risk*",
      "r2r-68": "APY +140%",
      "r2r-69": "Deposit",
      "r2r-70":
        "We provide you with one-click access to the best options on the market, the more profitability the greater the risk.",
      "r2r-71":
        '*Impermanent loss = "Impermanent loss refers to the possibility of reducing the value of your investment due to market volatility if you use pools with volatile coins.',
      "r2r-72": "Stable Pool",
      "r2r-72-a": "(no volatility)",
      "r2r-73":
        "Investing in DeFi can carry risks, such as asset volatility and potential vulnerabilities in smart contracts. Make sure to research and understand the mechanisms before investing.",
      "r2r-74":
        "I understand the potential risks associated with DeFi (Decentralized Finance).",
      "r2r-75": "I want to continue",
      "r2r-76": "Deposit: Moderate",
      "r2r-77": "Estimated Balance",
      "r2r-78":
        "Estimating your approximate balance after exchanging tokens at the best market rate and depositing into the liquidity pool through R2R DeFi.",
      "r2r-79":
        "*These rates may vary within seconds due to the operation of protocols and blockchain, up to a maximum slippage specified below",
      "r2r-80": "Max Slippage allowed",
      "r2r-81":
        'Generate passive income by participating with USDT (Tether) in Polygon. The platform handles the rest. Click "Continue" and withdraw your position whenever you want. If the pool entry is not 100% completed, the uninvested amount will be returned as USDT to your available balance.',
      "r2r-82": "Continue",
      "r2r-83": "Volatile Pool USDT/ETH",
      "r2r-83-a":
        'You need to understand what Impermanent Loss is before participating. When you provide liquidity to a decentralized pool and the prices of the assets in the pool change compared to when you deposited them, this pool is composed of the assets USDT/ETH. If the price of ETH rises or falls significantly relative to USDT, you could end up with more or less value in your position compared to holding them with your initial deposit. Although it is called "impermanent," the loss can become permanent if prices do not stabilize or return to their original state.',
      "r2r-84":
        "If the volatile asset experiences increases or decreases, the pool better compensates for any fluctuations compared to holding the asset in a portfolio and continues to generate ongoing returns.",
      "r2r-85": "I understand what Impermanent Loss is.",
      "r2r-85-a":
        "I understand the potential risks associated with DeFi (Decentralized Finance).",
      "r2r-86": "I want to continue",
      "r2r-87": "Volatile Pool",
      "r2r-87-a":
        'You need to understand what Impermanent Loss is before participating. When you provide liquidity to a decentralized pool and the prices of the assets in the pool change compared to when you deposited them, this pool is composed of the assets USDT/MATIC. If the price of MATIC rises or falls significantly relative to USDT, you could end up with more or less value in your position compared to holding them with your initial deposit. Although it is called "impermanent," the loss can become permanent if prices do not stabilize or return to their original state.',
      "r2r-88":
        "If the volatile asset experiences increases or decreases, the pool better compensates for any fluctuations compared to holding the asset in a portfolio and continues to generate ongoing returns.",
      "r2r-89":
        "I understand what Impermanent Loss is. I understand the potential risks associated with DeFi (Decentralized Finance).",
      "r2r-90": "I want to continue",
      "r2r-91":
        "We offer optional access to 4 plans with different risks and returns to cover the best market options in each segment.",
      "r2r-92":
        '*Impermanent loss = "Impermanent loss refers to the possibility of reducing the value of your investment due to market volatility if you use pools with volatile coins.',
      "r2r-93": "",
      "r2r-94": "My Positions",
      "r2r-95": "Value of your position",
      "r2r-96": "Referral Balance",
      "r2r-97": "Withdraw",
      "r2r-98": "Withdraw Referrals",
      "r2r-99": "Recent Activities",
      "r2r-100": "Withdraw position:",
      "r2r-101":
        "Withdraw your position, and you will receive your USDT (Tether), which will be available in your balance.",
      "r2r-102": "Available",
      "r2r-103": "Withdraw All",
      "r2r-104": "Withdraw: Moderate Plan",
      "r2r-105":
        "You can withdraw your funds to your bank account or your cryptocurrency wallet.",
      "r2r-106": "Select",
      "r2r-107": "",
      "r2r-108": "Referrals",
      "r2r-109": "Your referral link",
      "r2r-110": "Times Used",
      "r2r-111": "Balance (Total)",
      "r2r-112": "Your TIER right now is:",
      "r2r-113": "What does it mean to be TIER",
      "r2r-113-a": "It means you receive rewards from your",
      "r2r-113-b":
        "Remember that your TIER is updated every 24 hours according to the following R2R rules; if you have any issues, please contact us.",
      "r2r-114": "View Rules",
      "r2r-115": "Rules Table",
      "r2r-116": "OWN INVESTMENT (USDT)",
      "r2r-117": "DIRECT OWN REFERRALS",
      "r2r-118": "GROUP VOLUME INCLUDING OWN INVESTMENT (USDT)",
      "r2r-119": "LEVELS THAT RECEIVE REWARDS",
      "r2r-120": "PERCENTAGE",
      "r2r-121":
        "*Each referral counts for a maximum of 50% of the group volume to prevent irregularities, except at level 1.",
      "r2r-121-a":
        "**Values are approximate as they depend on different variables that are continuously changing. ***These percentages correspond to 20% of your referrals' earnings and are distributed every 24 hours.",
      "r2r-121-b":
        "***These percentages correspond to 20% of your referrals' earnings and are distributed every 24 hours.",
      "r2r-122": "Historical balance of your referrals.",
      "r2r-122-a": "Updated every 15 minutes",
      "r2r-123": "",
      "r2r-124": "FAQs",
      "r2r-125":
        "What is R2R? R2R is an exclusive app that offers its members various options to participate in decentralized finance (DeFi) via blockchain, simplifying the process with just two clicks from your Google account.",
      "r2r-126":
        "How can I start using R2R? Access to R2R is by invitation or referral only. Once you receive an invitation, simply enter the code on the home page to start using the platform.",
      "r2r-127":
        "What if I don't have a web3 wallet? R2R is designed to be accessible to everyone. Through our fully automated process, we create a wallet for you, cover the fees, and all you need to do is choose the option that best suits your needs/profile.",
      "r2r-128":
        "How is passive income generated in R2R? Passive income is generated through the liquidity pools we offer on various protocols and blockchain networks. Users can choose the option that best fits their risk profile.",
      "r2r-129":
        "How often is passive income generated? Income is generated continuously. Every 24 hours, we reinvest the earnings with compound interest to maximize returns.",
      "r2r-130":
        "Is it safe to use R2R? Our smart contracts are audited, and we work with the most secure and experienced protocols in the DeFi sector, ensuring maximum security.",
      "r2r-131":
        "What fee does R2R charge? The fee is 13% for all services including, wallet creation, and payment of fees within the platform.",
      "r2r-132":
        "Can I withdraw my money at any time? Yes, you will always have full control over your assets. R2R is app that facilitates participation in liquidity pools, but you are the owner of your funds at all times.",
      "r2r-133":
        "What happens if I get hacked? We do not have access to your money. It is a self-custody service. Each user is responsible for the security of their own assets.",
      "r2r-134":
        "How can I get an invitation to R2R? You can only access R2R by invitation using a referral code from an active member.",
      "r2r-135":
        "What types of participation are available in R2R? We offer a variety of participation options in different liquidity pools, covering various blockchain networks and DeFi protocols to diversify your portfolio.",
      "r2r-136":
        "What is a liquidity pool? A liquidity pool is a collection of funds locked in a smart contract that are used to facilitate trading on decentralized exchanges (DEX). Pool participants earn rewards in exchange for providing liquidity.",
      "r2r-137":
        "What is blockchain, and why is it important for R2R? Blockchain is a decentralized ledger technology that ensures the transparency, security, and immutability of transactions. R2R uses blockchain to offer secure and transparent investment options.",
      "r2r-138":
        "What benefits do I get as an R2R member? As an R2R member, you will have exclusive access to DeFi participation opportunities, personalized support, and tools to maximize your passive income efficiently and securely.",
      "r2r-139":
        "What should I do if I have problems with my account? You can contact our support team through the app by sending an email to our customer service.",
      "r2r-140": "",
      "r2r-141": "Log Out",
      "r2r-142": "Notifications",
      "r2r-143": "Add Balance",
      "r2r-a-1": "What is R2R?",
      "r2r-a-2":
        "R2R is an exclusive app that offers its members various options to participate in decentralized finance (DeFi) via blockchain, simplifying the process with just two clicks from your Google account.",
      "r2r-a-3": "How can I start using R2R?",
      "r2r-a-4":
        "Access to R2R is by invitation or referral only. Once you receive an invitation, simply enter the code on the home page to start using the platform.",
      "r2r-a-5": "What if I don't have a web3 wallet?",
      "r2r-a-6":
        "R2R is designed to be accessible to everyone. Through our fully automated process, we create a wallet for you, cover the fees, and all you need to do is choose the option that best suits your needs/profile.",
      "r2r-a-7": "See more",
      "r2r-a-8":
        "Your R2R account is yours, and it is your responsibility. With your PK or private key information, anyone can access your funds. NEVER reveal this information to ANYONE. R2R will NEVER ask you for it. If someone externally asked you to do so is a fraud, contact us as soon as possible.",
      "r2r-a-9":
        "Your Private Key is the owner of this R2R account since you are connected from a web3 wallet",
      "r2r-a-10": "Personalize your account",
      "r2r-a-11": "Your Profile Picture",
      "r2r-a-12": "Try Again",
      "r2r-a-13": "Recover USDT",
      "r2r-a-14": "Calculating",
      "r2r-a-15": "Copy",
      "r2r-a-16":
        "Daily profit estimate at this time without taking into account the possible revaluation of volatile assets",
      "r2r-a-17": "Remaning Balance",
      "r2r-a-18":
        "Do you have a frozen deposit or withdrawal transaction? Don't worry. Click the button below to verify and return any leftover USDT to your available balance.",
      "r2r-a-19": "You have no balance available to withdraw",
      "r2r-a-20": "Please select a payment method",

      "r2r-a-21": "Day",
      "r2r-a-22": "Month",
      "r2r-a-23": "Year",

      "r2r-a-24": "What is a liquidity pool?",
      "r2r-a-24-a":
        "A smart contract where you contribute funds in exchange for commissions and rewards.",
      "r2r-a-24-b": "An unsecured lending platform ",
      "r2r-a-25": "What does choosing a high risk pool imply?",
      "r2r-a-25-a": "Potentially higher returns, but greater risk of loss.",
      "r2r-a-25-b": "Lower returns, but more security. ",
      "r2r-a-26": "What is impermanent loss?",
      "r2r-a-26-a":
        "Temporary loss in the value of the funds contributed to a pool, as compared to holding the funds. ",
      "r2r-a-26-b": "Fixed fee for entering or exiting a pool. ",
      "r2r-a-27": "What does it mean to “diversify” in liquidity pools?",
      "r2r-a-27-a":
        "Investing in several pools with different levels of risk to minimize losses.",
      "r2r-a-27-b":
        "Increasing investment in a single pool to maximize returns. (Incorrect)",
      "r2r-a-28":
        "Do you understand how external factors, such as monetary policy, affect pool returns?",
      "r2r-a-28-a":
        "Yes, changes can influence returns and stability, especially in riskier pools.",
      "r2r-a-28-b": "No, external factors do not impact returns.",
      "r2r-a-29": "Next",
      "r2r-a-30": "Congratulations! You have completed the questionnaire.",
      "r2r-a-31": "Sorry! You have not completed the questionnaire.",
      "r2r-a-32":
        "At R2R, we help you participate in DeFi options in a simple and agile way. Before continuing, complete this short questionnaire to ensure you understand the product and its risks. You must correctly answer the following 5 questions:",
      "r2r-a-33": "Confirm deposit",
      "r2r-a-34": "Amount",
      "r2r-a-35": "Price",
      "r2r-a-36": "Confirm",
      "r2r-a-37": "Waiting for transaction response, it may take a few seconds",
      "r2r-a-38": "Calculated",
      "r2r-a-39": "View Transaction",
      "r2r-a-40": "R2R Presentation",
      "r2r-a-41": "Connecting with Metamask or web wallet3",
      "r2r-a-42": "How to import your R2R account",
      "r2r-a-43": "Old Method",
      "r2r-a-44":
        "If you have money deposited in this pool we recommend withdrawing it and putting it in a new option",
      "r2r-a-45": "Strategy",
      "r2r-a-46": "Volatile Coins",
      "r2r-a-47": "(No risk of Imperment Loss)",
      "r2r-a-48": "(Better in side markets)",
      "r2r-a-49":
        "It allows you to optimize capital by focusing it on specific price ranges.",
      "r2r-a-50": "(Better in volatile markets)",
      "r2r-a-51": "Fetching Pools, please wait a moment...",
      "r2r-a-52": "Fetching, please wait a moment...",
      "r2r-a-53": "Important Notice about Liquidity Pools",
      "r2r-a-54":
        "By participating in this pool, there is a risk of impermanent loss. If asset prices fluctuate, you could withdraw less value than your original deposit, especially in pairs with volatile assets. These pools are self-balancing to mitigate fluctuations, but losses can become permanent if prices do not stabilize.",
      "r2r-a-55": "Start your adventure with us by clicking here",
    },
  },
  es: {
    translation: {
      "home-a-1": "€",
      "home-a-2": "Cantidad en Euros",
      "home-a-3": "30 Días",
      "home-a-4":
        "¿Necesitas comprar USDT? Puedes adquirirlos en exchanges Onramp.",
      "home-a-5": "Aquí podrás encontrar algunos ejemplos:",
      "home-a-6": "Aquí",
      "home-a-7": "Debes Firmar para Iniciar Sesion",
      "home-a-8": "Perfil",
      "home-a-9": "Referidos",
      "home-a-10": "Conectando",
      "home-a-11": "Reconectando",
      "home-a-12": "Crea tu wallet/Ingresa",
      "home-a-13": "Ingresa tu código de referido",
      "home-a-14": "El código de referido debe tener 6 dígitos.",
      "home-a-": "",

      "home-1": "Return to Revenue",
      "home-2":
        "Ingresos pasivos sin complicaciones. Elige un plan, tu tienes el control, nosotros automatizamos todo.",
      "home-3": "Nunca ha sido tan fácil",
      "home-4":
        "Haz trabajar tu dinero usando las mejores oportunidades de las finanzas descentralizas DeFi en 2 cliks",
      "home-5": "Moderado",
      "home-6": "Agresivo",
      "home-7": "Fuego",
      "home-8": "Locos",
      "home-9": "Inversión",
      "home-10": "Tiempo",
      "home-11": "Tu saldo",
      "home-12": "Elige el plan que mejor se ajuste a tu perfil",
      "home-13": "Solo elige un plan, el resto funciona en piloto automatico.",
      "home-14":
        "Haz trabajar tu dinero usando las mejores oportunidades de las finanzas descentralizas DeFi en 2 cliks.",
      "home-15": "Comienza",
      "home-16": "Nunca ha sido tan fácil",
      "home-17": "Liquidez siempre disponible",
      "home-18": "Siempre eres dueño de tu dinero",
      "home-19": "Reparto de beneficios cada 24h",
      "home-20": "¿Cómo funciona?",
      "home-21":
        "Acercamos las oportunidades en DeFI (Finanzas Descentralizadas) sin complejidades tecnologicas",
      "home-22": "Crea tu cuenta desde Google",
      "home-23": "Crea tu cuenta y tu wallet solo accediendo desde Google",
      "home-24": "Deposita los fondos",
      "home-24-a": "Desde USDT directo a tu wallet",
      "home-25": "Elige un plan",
      "home-26": "Elige el plan que más se adapte a ti.",
      "home-27": "Recibe ingresos pasivos",
      "home-28": "Disfruta observando sin complicaciones como crece tu dinero",
      "home-29": "Sin complicaciones",
      "home-30":
        "Retira tu liquidez en cualquier momento y disfruta de los beneficios generados",
      "home-31": "Seguridad + Confianza",
      "home-31-a":
        "Entidad regulada en el Banco de Españaa para el intercambio de FIAT - Crypto",

      "home-32": "Tu wallet = tus criptomonedas.",
      "home-32-a":
        "Te generamos una billetera encriptada a la que solo tu tendras acceso, otorgándote así el control y poder de la autocustodia.",
      "home-33": "No te preocupes por el gas-fee",
      "home-33-a":
        "El gas-fue es el peaje para realizar transacciones en la blockchain, todo esta automatizado sin coste adicional para ti.",
      "home-34": "Oportunidades en DeFI en dos clicks.",
      "home-34-a":
        "Te facilitamos el acceso opcional a 4 planes con diferentes riesgos y rentabilidades para cubrir las mejores opciones del mercado en cada segmento, seleccionados por expertos en el campo.",
      "home-35": "Interés compuesto automatizado.",
      "home-35-a":
        "uestra tecnología, reinvierte los beneficios generados, para multiplicar las ganancias gracias al interés compuesto.",
      "home-36": "Cash in/out en USDT.",
      "home-36-a": "Tú decides donde retirar tu dinero en cualquier momento",
      "home-37": "Se parte de nuestra comunidad",
      "home-38": "Inicio",
      "home-39": "Aviso Legal",
      "home-40": "Terminos y condiciones",
      "home-42":
        "Bienvenido/a. Return to Revenue está en fase de acceso anticipado y es accesible únicamente mediante un código de referido para early adopters. Consideramos expandir el acceso en el futuro. Para asistencia, contáctenos. Gracias.",
      "home-43": "Estoy de acuerdo con los",
      "home-44": "Estoy de acuerdo con el ",
      "home-45": "Confirmar Código",

      "r2r-1": "Dashboard",
      "r2r-2": "Mi Perfil",
      "r2r-3": "Participar",
      "r2r-4": "Mis Posiciones",
      "r2r-5": "Referidos",
      "r2r-6": "FAQ",
      "r2r-7": "",

      "r2r-b-1": "¿Qué es R2R?",
      "r2r-b-2":
        "R2R es una plataforma exclusiva que ofrece a sus miembros diversas opciones de participar en finanzas descentralizadas (DeFi) a través de blockchain, facilitando el proceso con dos clics desde tu cuenta de Google.",
      "r2r-b-3": "¿Cómo puedo empezar a utilizar R2R?",
      "r2r-b-4":
        "El acceso a R2R es únicamente por invitación o referencia. Una vez recibas una invitación, simplemente ingresa el código en la página de inicio para comenzar a utilizar la plataforma",
      "r2r-b-5": "¿Qué hago si no tengo una wallet web3?",
      "r2r-b-6":
        "R2R está diseñado para ser accesible a todos. Desde nuestro proceso totalmente automatizado te creamos el wallet o billetera digital, pagamos los fees y lo único que tienes que hacer es elegir la opción que más se ajuste a tus necesidades/ tú perfil.",
      "r2r-b-7": "¿Cómo se generan los ingresos pasivos en R2R?",
      "r2r-b-8":
        "Los ingresos pasivos se generan a través de los pools de liquidez que ofrecemos en diversos protocolos y redes blockchain. Los usuarios pueden elegir la opción que mejor se adapte a su perfil de riesgo.",
      "r2r-b-9": "¿Cada cuánto tiempo se generan los ingresos pasivos?",
      "r2r-b-10":
        "Los ingresos se generan de manera continua. Cada 24 horas, reinvertimos las ganancias con interés compuesto para maximizar los rendimientos.",
      "r2r-b-11": "¿Es seguro utilizar R2R?",
      "r2r-b-12":
        "Nuestros contratos inteligentes (smart contracts) están auditados y trabajamos con los protocolos más seguros y experimentados del sector DeFi, garantizando la máxima seguridad.",
      "r2r-b-13": "¿Qué comisión cobra R2R?",
      "r2r-b-14":
        "La comisión es de 13% por toda la gestión, creación de wallets y pagos de los fees dentro de la plataforma. ",
      "r2r-b-15": "¿Puedo retirar mi dinero en cualquier momento?",
      "r2r-b-16":
        "Sí, siempre tendrás control total sobre tus activos. R2R es una plataforma que facilita la participación en pools de liquidez, pero tú eres el propietario de tus fondos en todo momento",
      "r2r-b-17": "¿Qué pasa si me hackean?",
      "r2r-b-18":
        "Nosotros no tenemos acceso a tu dinero. Es un servicio autocustodia. Cada usuario es responsable de la seguridad de sus propios activos",
      "r2r-b-19": "¿Cómo puedo obtener una invitación para R2R?",
      "r2r-b-20":
        "Solo podrás acceder a R2R por invitación mediante un código de referido de un miembro activo. ",
      "r2r-b-21": "¿Qué tipos de participación están disponibles en R2R?",
      "r2r-b-22":
        "Ofrecemos una variedad de opciones de participación en distintos pools de liquidez, abarcando diferentes redes blockchain y protocolos DeFi para diversificar tu portafolio",
      "r2r-b-23": "¿Qué es un pool de liquidez?",
      "r2r-b-24":
        "Un pool de liquidez es un conjunto de fondos bloqueados en un contrato inteligente que se utilizan para facilitar el comercio en exchanges descentralizados (DEX). Los participantes del pool ganan recompensas a cambio de proporcionar liquidez.",
      "r2r-b-25": "¿Qué es la blockchain y por qué es importante para R2R?",
      "r2r-b-26":
        "La blockchain es una tecnología de registro descentralizado que garantiza la transparencia, seguridad y la inmutabilidad de las transacciones. R2R utiliza la blockchain para ofrecer opciones de inversión seguras y transparentes",
      "r2r-b-27": "¿Qué beneficios obtengo al ser miembro de R2R?",
      "r2r-b-28":
        "Como miembro de R2R, tendrás acceso exclusivo a oportunidades de participar en DeFi,soporte personalizado, y herramientas para maximizar tus ingresos pasivos de manera eficiente y segura",
      "r2r-b-29": "¿Qué debo hacer si tengo problemas con mi cuenta?",
      "r2r-b-30":
        "Puedes contactar a nuestro equipo de soporte a través de la plataforma enviando un correo electrónico a nuestro servicio de atención al cliente",

      "r2r-8": "Dashboard",
      "r2r-9": "Disponible",
      "r2r-10": "Invertido",
      "r2r-11": "Referidos",
      "r2r-12": "Añadir",
      "r2r-13": "Añadir saldo",
      "r2r-14": "Metodo",
      "r2r-15": "Disponible",
      "r2r-16":
        "Ya puedes ingresar USDT en la red de Polygon en tu wallet de R2R",
      "r2r-17": "Seleccionar",
      "r2r-18": "Añadir saldo a tu cuenta R2R",
      "r2r-19": "Enviar a",
      "r2r-20":
        "Ingresa el saldo que desees en USDT (Tether) a tu cuenta R2R a través de la red de Polygon desde cualquier wallet de tu elección.",
      "r2r-21": "Copiar Dirección",
      "r2r-22": "Retirar",
      "r2r-23": "Retirar saldo",
      "r2r-24":
        "Ya puedes retirar tus USDT en la red de Polygon a cualquier wallet externa a R2R. Recuerda que opere en la misma red.",
      "r2r-25": "Retirar saldo fuera de R2R",
      "r2r-26": "Disponible",
      "r2r-27":
        "Ingresa el saldo que desees en USDT (Tether) a tu cuenta R2R a través de la red de Polygon desde cualquier wallet de tu elección.",
      "r2r-28": "Continuar",
      "r2r-29": "Participar",
      "r2r-30": "",
      "r2r-31": "Mis Posiciones",
      "r2r-32": "~Diario",
      "r2r-33": "Panel informativo sobre tus posiciones abiertas dentro de r2r",
      "r2r-34": "Saldo Total",
      "r2r-35": "Panel informativo acerca de la suma de todas tus posiciones",
      "r2r-36": "Saldo Total",
      "r2r-37": "Actividad Reciente",
      "r2r-38":
        "Panel informativo de la interacción de tu wallet con r2r: entradas y salidas",
      "r2r-39": "",
      "r2r-40": "Mi Perfil",
      "r2r-41": "Personaliza tu cuenta",
      "r2r-42": "NingUn archivo seleccionado",
      "r2r-43": "Cambiar Avatar",
      "r2r-44": "Si quieres personaliza tu nombre",
      "r2r-45": "Balance en tu wallet",
      "r2r-46": "Tu wallet en R2R | Solo tú tienes acceso a tus fondos",
      "r2r-47": "Guardar",
      "r2r-48": "Activar mi 2FA",
      "r2r-49": "Exportar tu Private Key",
      "r2r-50": "Debes activar tu 2FA para exportar tu llave privada",
      "r2r-51": "",
      "r2r-52": "Participar",
      "r2r-53": "Moderado",
      "r2r-54": "Posicion con monedas estables",
      "r2r-55": "Sin riesgos de volatilidad",
      "r2r-56": "año",
      "r2r-57": "Agresivo",
      "r2r-58": "Posición con monedas estables y volátiles másconocidas",
      "r2r-59": "Riesgo Impermanent loss *",
      "r2r-60": "APY ~442,14 %",
      "r2r-61": "Fuego",
      "r2r-62":
        "Posición con monedas estables o solo volátiles buscando mayor rendimiento",
      "r2r-63": "Riesgo Impermanent loss *",
      "r2r-64": "APY ~329,4 %",
      "r2r-65": "De Locos",
      "r2r-66":
        "Posición con monedas volátiles más desconocidas buscando mayor rendimiento en menos tiempo”",
      "r2r-67": "Riesgo Impermanent loss *",
      "r2r-68": "APY +140%",
      "r2r-69": "Participar",
      "r2r-70":
        "Te facilitamos el acceso a golpe de click en las mejores opciones del mercado, cuanto más rentabilidad mayor riesgo.",
      "r2r-71":
        '* Impermanent loss = "El impermanent loss hace referencia a la posibilidad de reducir el valor de tu inversión debido a la volatilidad del mercado si usas pools con monedas volátiles."',
      "r2r-72": "Pool Estable",
      "r2r-72-a": "(sin volatilidad)",
      "r2r-73":
        "Invertir en DeFi puede presentar riesgos, como la volatilidad de los activos y posibles vulnerabilidades en los contratos inteligentes. Asegurate de investigar y comprender los mecanismos antes de invertir.",
      "r2r-74":
        "Entiendo los posibles riesgos derivados de DeFi (Finanzas Descentralizadas).",
      "r2r-75": "Quiero Continuar",
      "r2r-76": "Participar: Moderado",
      "r2r-77": "Saldo estimado",
      "r2r-78":
        "Estimando tu saldo aproximado después de intercambiar tokens con la mejor tasa del mercado e ingreso en el pool de liquidez a través de R2R DeFI.",
      "r2r-79":
        "* Estas tasas pueden variar en segundos por el propio funcionamiento de los protocolos y blockchain hasta un deslizamiento maximo especificado mas abajo",
      "r2r-80": "Max Slippage permitido",
      "r2r-81":
        'Genera ingresos pasivos participando con USDT (Tether) en Polygon. La plataforma se encarga del resto. Pulsa "Continuar" y retira tu posición cuando desees. Si la entrada en el pool no se completa al 100% lo no invertido volvera de forma en USDT a tu saldo disponible.',
      "r2r-82": "Continuar",
      "r2r-83": "Pool Volátil USDT/ETH",
      "r2r-83-a":
        'Tienes que conocer antes de participar que es el Impermanent Loss. Cuando proporcionas liquidez a un pool descentralizado y los precios de los activos en el pool cambian en comparación con el momento en que los depositaste. Este pool se compone de los activos USDT/ETH. Si el precio de ETH sube o baja significativamente respecto a USDT, podrias terminar con más o menos valor en tu posición en comparación con mantenerlos con tu primer depósito. Aunque se le llama "no permanente", la perdida puede ser permanente si los precios no se estabilizan o no vuelven a su estado original.',
      "r2r-84":
        "Si el activo volátil experimenta subidas o bajadas, el pool compensa mejor cualquier fluctuación en comparación con mantener el activo en cartera y sigue generando rendimientos continuos.",
      "r2r-85": "He entendido que es el Impermanent Loss",
      "r2r-85-a":
        "Entiendo los posibles riesgos derivados de DeFi (Finanzas Descentralizadas).",
      "r2r-86": "Quiero Continuar",
      "r2r-87": "Pool Volátil",
      "r2r-87-a":
        'Tienes que conocer antes de participar que es el Impermanent Loss. Cuando proporcionas liquidez a un pool descentralizado y los precios de los activos en el pool cambian en comparación con el momento en que los depositaste. Este pool se compone de los activos USDT/MATIC. Si el precio de MATIC sube o baja significativamente respecto a USDT, podrías terminar con más o menos valor en tu posición en comparación con mantenerlos con tu primer depósito. Aunque se le llama "no permanente", la perdida puede ser permanente si los precios no se estabilizan o no vuelven a su estado original.',
      "r2r-88":
        "Si el activo volátil experimenta subidas o bajadas, el pool compensa mejor cualquier fluctuación en comparación con mantener el activo en cartera y sigue generando rendimientos continuos.",
      "r2r-89":
        "He entendido que es el Impermanent Loss.Entiendo los posibles riesgos derivados de DeFi (Finanzas Descentralizadas).",
      "r2r-90": "Quiero Continuar",
      "r2r-91":
        "Te facilitamos el acceso opcional a 4 planes con diferentes riesgos y rentabilidades para cubrir las mejores opciones del mercado en cada segmento.",
      "r2r-92":
        '* Impermanent loss = "El impermanent loss hace referencia a la posibilidad de reducir el valor de tu inversión debido a la volatilidad del mercado si usas pools con monedas volátiles.',
      "r2r-93": "",
      "r2r-94": "Mis Posiciones",
      "r2r-95": "Valor de tu posición",
      "r2r-96": "Balance Referidos",
      "r2r-97": "Retirar",
      "r2r-98": "Retirar Referidos",
      "r2r-99": "Actividades recientes",
      "r2r-100": "Retirar posición:",
      "r2r-101":
        "Retira tu posición y recibirás tus USDT (Tether), que quedarón disponibles en tu saldo disponible.",
      "r2r-102": "Disponible",
      "r2r-103": "Retirar Todo",
      "r2r-104": "Withdraw : Plan Moderado",
      "r2r-105":
        "Puedes Retirar tus fondos a tu cuenta bancaria o a tu billetera de criptomonedas.",
      "r2r-106": "Seleccionar",
      "r2r-107": "",
      "r2r-108": "Referidos",
      "r2r-109": "Tu link de referido",
      "r2r-110": "Cantidad utilizadas",
      "r2r-111": "Balance (Total)",
      "r2r-112": "Tu TIER ahora mismo es:",
      "r2r-113": "Qué significa ser TIER",
      "r2r-113-a": "Significa que recibes recompensas de tus",
      "r2r-113-b":
        "Recuerda que tu TIER se actualiza cada 24h segun las siguientes normas de R2R, cualquier cosa ponte en contacto con nosotros",
      "r2r-114": "Ver Normas",
      "r2r-115": "Tabla de Normas",
      "r2r-116": "INVERSIÓN PROPIA (USDT)",
      "r2r-117": "REFERIDOS DIRECTOS PROPIOS",
      "r2r-118": "VOLUMEN DE GRUPO INCLUIDA LA INVERSIO?N PROPIA (USDT)",
      "r2r-119": "NIVELES QUE RECIBE RECOMPENSA",
      "r2r-120": "PORCENTAJE",
      "r2r-121":
        "* De cada referido vale el maximo de un 50% del volumen del grupo para evitar irregularidades excepto en el nivel 1.",
      "r2r-121-a":
        "** Los valores son aproximados ya que dependen de diferentes variables que se estón modificando de forma continua.*** Estos porcentajes pertenecen al 20% de las ganancias de tus referidos y se reparten cada 24h.",
      "r2r-121-b":
        "*** Estos porcentajes pertenecen al 20% de las ganancias de tus referidos y se reparten cada 24h.",
      "r2r-122": "Saldo historico de tus referidos.",
      "r2r-122-a": "Se actualiza cada 15 minutos",
      "r2r-123": "",
      "r2r-124": "FAQs",
      "r2r-125":
        "Qué es R2R?R2R es una plataforma exclusiva que ofrece a sus miembros diversas opciones de participar en finanzas descentralizadas (DeFi) a través de blockchain, facilitando el proceso con dos clics desde tu cuenta de Google.",
      "r2r-126":
        "Cómo puedo empezar a utilizar R2R?El acceso a R2R es ónicamente por invitación o referencia. Una vez recibas una invitación, simplemente ingresa el código en la página de inicio para comenzar a utilizar la plataforma",
      "r2r-127":
        "Qué hago si no tengo una wallet web3?R2R estú diseñado para ser accesible a todos. Desde nuestro proceso totalmente automatizado te creamos el wallet o billetera digital, pagamos los fees y lo ónico que tienes que hacer es elegir la opción que más se ajuste a tus necesidades/ tú perfil.",
      "r2r-128":
        "Cómo se generan los ingresos pasivos en R2R?Los ingresos pasivos se generan a través de los pools de liquidez que ofrecemos en diversos protocolos y redes blockchain. Los usuarios pueden elegir la opción que mejor se adapte a su perfil de riesgo.",
      "r2r-129":
        "Cada cuónto tiempo se generan los ingresos pasivos?Los ingresos se generan de manera continua. Cada 24 horas, reinvertimos las ganancias con interés compuesto para maximizar los rendimientos.",
      "r2r-130":
        "Es seguro utilizar R2R?Nuestros contratos inteligentes (smart contracts) estón auditados y trabajamos con los protocolos más seguros y experimentados del sector DeFi, garantizando la máxima seguridad.",
      "r2r-131":
        "Qué comisión cobra R2R?La comisión es de 13% por toda la gestión, creación de wallets y pagos de los fees dentro de la plataforma.",
      "r2r-132":
        "Puedo retirar mi dinero en cualquier momento?Sí, siempre tendrás control total sobre tus activos. R2R es una plataforma que facilita la participación en pools de liquidez, pero tú eres el propietario de tus fondos en todo momento",
      "r2r-133":
        "Qué pasa si me hackean?Nosotros no tenemos acceso a tu dinero. Es un servicio autocustodia. Cada usuario es responsable de la seguridad de sus propios activos",
      "r2r-134":
        "Cómo puedo obtener una invitación para R2R?Solo podrás acceder a R2R por invitación mediante un código de referido de un miembro activo.",
      "r2r-135":
        "Qué tipos de participación estón disponibles en R2R?Ofrecemos una variedad de opciones de participación en distintos pools de liquidez, abarcando diferentes redes blockchain y protocolos DeFi para diversificar tu portafolio",
      "r2r-136":
        "Qué es un pool de liquidez?Un pool de liquidez es un conjunto de fondos bloqueados en un contrato inteligente que se utilizan para facilitar el comercio en exchanges descentralizados (DEX). Los participantes del pool ganan recompensas a cambio de proporcionar liquidez.",
      "r2r-137":
        "Qué es la blockchain y por qué es importante para R2R?La blockchain es una tecnologáa de registro descentralizado que garantiza la transparencia, seguridad y la inmutabilidad de las transacciones. R2R utiliza la blockchain para ofrecer opciones de inversión seguras y transparentes",
      "r2r-138":
        "Qué beneficios obtengo al ser miembro de R2R?Como miembro de R2R, tendrás acceso exclusivo a oportunidades de participar en DeFi,soporte personalizado, y herramientas para maximizar tus ingresos pasivos de manera eficiente y segura",
      "r2r-139":
        "Qué debo hacer si tengo problemas con mi cuenta?Puedes contactar a nuestro equipo de soporte a través de la plataforma enviando un correo electrónico a nuestro servicio de atención al cliente",
      "r2r-140": "",
      "r2r-141": "Cerrar sesión",
      "r2r-142": "Notificaciones",
      "r2r-143": "Añadir saldo",
      "r2r-a-1": "¿Qué es R2R?",
      "r2r-a-2":
        "R2R es una plataforma exclusiva que ofrece a sus miembros diversas opciones de participar en finanzas descentralizadas (DeFi) a través de blockchain, facilitando el proceso con dos clics desde tu cuenta de Google",
      "r2r-a-3": "¿Cómo puedo empezar a utilizar R2R?",
      "r2r-a-4":
        "El acceso a R2R es únicamente por invitación o referencia. Una vez recibas una invitación, simplemente ingresa el código en la página de inicio para comenzar a utilizar la plataforma",
      "r2r-a-5": "¿Qué hago si no tengo una wallet web3?",
      "r2r-a-6":
        "R2R está diseñado para ser accesible a todos. Nos ocupamos de todo. Todo está automatizado. Te creamos el wallet o billetera digital, pagamos los fees y lo único que tienes que hacer es elegir la opción que más se ajuste a tus necesidades.",
      "r2r-a-7": "Ver Todas",
      "r2r-a-8":
        "Tu cuenta en R2R es tuya, y es tu responsabilidad. Con la información de tu PK o clave privada, cualquiera podrá acceder a tus fondos. NUNCA reveles esta información a NADIE. R2R NUNCA te lo va a solicitar. Si alguien externo te lo ha pedido es un fraude, ponte en contacto con nosotros lo antes posible.",
      "r2r-a-9":
        "Tu Private Key ya es la propietaria de esta cuenta R2R ya queestás conectad@ desde una wallet web3",
      "r2r-a-10": "Personaliza tu cuenta",
      "r2r-a-11": "Tu foto de perfil",
      "r2r-a-12": "Volver Intentar",
      "r2r-a-13": "Recuperar USDT",
      "r2r-a-14": "Calculando",
      "r2r-a-15": "Copiado",
      "r2r-a-16":
        "Estimación de ganancia diaria en este momento sin tener en cuenta la posible revalorización de los activos volátiles",
      "r2r-a-17": "Saldo sobrante",
      "r2r-a-18":
        "¿Tienes una transacción de depósito o retiro congelada? No te preocupes. Haz clic en el siguiente botón para verificar y devolver cualquier sobrante en USDT a tu saldo disponible.",
      "r2r-a-19": "No tienes saldo disponible para retirar",
      "r2r-a-20": "Por favor selecciona un metodo de pago",
      "r2r-a-21": "Dia",
      "r2r-a-22": "Mes",
      "r2r-a-23": "Año",

      "r2r-a-24": "¿Qué es un fondo común de liquidez?",
      "r2r-a-24-a":
        "Un contrato inteligente en el que aportas fondos a cambio de comisiones y recompensas.",
      "r2r-a-24-b": "Una plataforma de préstamos sin garantía",
      "r2r-a-25": "¿Qué implica elegir un pool de alto riesgo?",
      "r2r-a-25-a":
        "Rendimientos potencialmente mayores, pero mayor riesgo de pérdida.",
      "r2r-a-25-b": "Retornos más bajos, pero más seguridad.",
      "r2r-a-26": "¿Qué es la pérdida impermanente?",
      "r2r-a-26-a":
        "Pérdida temporal en el valor de los fondos aportados a un fondo común, en comparación con la tenencia de los fondos.",
      "r2r-a-26-b": "Tarifa fija por entrar o salir de una piscina. ",
      "r2r-a-27": "¿Qué significa “diversificar” los fondos de liquidez?",
      "r2r-a-27-a":
        "Invertir en varios pools con diferentes niveles de riesgo para minimizar las pérdidas.",
      "r2r-a-27-b":
        "Aumentar la inversión en un solo fondo para maximizar la rentabilidad. (Incorrecto)",
      "r2r-a-28":
        "¿Entiendes cómo los factores externos, como la política monetaria, afectan los rendimientos del pool?",
      "r2r-a-28-a":
        "Sí, los cambios pueden influir en los rendimientos y la estabilidad, especialmente en los grupos más riesgosos.",
      "r2r-a-28-b": "No, los factores externos no afectan la rentabilidad.",

      "r2r-a-29": "Siguiente",
      "r2r-a-30": "¡Felicidades! Has completado el cuestionario.",
      "r2r-a-31": "¡Lo sentimos! No has completado el cuestionario.",
      "r2r-a-32":
        "En R2R, te ayudamos a participar en opciones DeFi de manera simple y ágil. Antes de continuar, completa este breve cuestionario para asegurar que comprendes el producto y sus riesgos. Debes responder correctamente a las siguientes 5 preguntas:",
      "r2r-a-33": "Confirmar deposito",
      "r2r-a-34": "Monto",
      "r2r-a-35": "Precio",
      "r2r-a-36": "Confirmar",
      "r2r-a-37":
        "Esperando respuesta de transacción, puede demorar unos segundos",
      "r2r-a-38": "Calculado",
      "r2r-a-39": "Ver Transacción",
      "r2r-a-40": "Presentación de R2R",
      "r2r-a-41": "Conectarse con Metamask o wallet web3",
      "r2r-a-42": "Como importar tu cuenta de R2R",
      "r2r-a-43": "Antiguo",
      "r2r-a-44":
        "Si tienes dinero depositado en este pool recomendamos sacarlo y meterlo en una nueva opción",
      "r2r-a-45": "Estrategia",
      "r2r-a-46": "Monedas Volátiles",
      "r2r-a-47": "(No hay riesgo de Imperment Loss)",
      "r2r-a-48": "(Mejor en mercados laterales)",
      "r2r-a-49":
        "Permite optimizar el capital enfocándolo en rangos de precios específicos.",
      "r2r-a-50": "(Mejor en mercados volátiles)",
      "r2r-a-51": "Cargando pools espera un momento...",
      "r2r-a-52": "Cargando espera un momento...",
      "r2r-a-53": "Aviso Importante sobre Pools de Liquidez",
      "r2r-a-54":
        "Al participar en este pool, existe riesgo de impermanent loss. Si los precios de los activos fluctúan, podrías retirar menos valor que tu depósito original, especialmente en pares con activos volátiles. Estos pools se autobalancean para mitigar fluctuaciones, pero las pérdidas pueden volverse permanentes si los precios no se estabilizan.",
      "r2r-a-55": "Inicia tu aventura con nosotros haciendo click aquí",
    },
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    // debug: process.env.NODE_ENV === "development",
    resources,
    lng: localStorage.getItem("i18nextLng") || "es",
    fallbackLng: "es",
  });
export default i18n;
