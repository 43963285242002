import "./style.css";
import Modal from "..";
import Icon from "../../Icon";
import CustomSlot from "../../CustomSlot";
import Lottie from "lottie-react";
import success_animation from "../../../assets/animations/Success_Square_lottie.json";
import alert_animation from "../../../assets/animations//Alerts_lottie.json";
import search_animation from "../../../assets/animations/Search_Remove_lottie.json";
import time_animation from "../../../assets/animations/Time_Hourglass_lottie.json";
import edit_animation from "../../../assets/animations/Edit_Contract_lottie.json";
import { useTranslation } from "react-i18next";

const ModalSuccess = ({ visible, onClose, info }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="ModalSuccess--container aic">
        <Icon
          name="x"
          className="wave aim ModalSuccess-close hover opacity7"
          onClick={onClose}
        />
        {!!info?.status && (
          <div className="ModalSuccess-input aic">
            {info?.status === "ExecSuccess" && (
              <Lottie
                animationData={success_animation}
                loop
                style={{
                  height: "200px",
                  width: "100%",
                }}
              />
            )}
            {info?.status === "ExecReverted" && (
              <Lottie
                animationData={alert_animation}
                loop
                style={{
                  height: "200px",
                  width: "100%",
                }}
              />
            )}
            {info?.status === "Cancelled" && (
              <Lottie
                animationData={search_animation}
                loop
                style={{
                  height: "200px",
                  width: "100%",
                }}
              />
            )}
            {info?.status === "loading" && (
              <Lottie
                animationData={time_animation}
                loop
                style={{
                  height: "200px",
                  width: "100%",
                }}
              />
            )}
            {info?.status === "edit" && (
              <Lottie
                animationData={edit_animation}
                loop
                style={{
                  height: "200px",
                  width: "100%",
                }}
              />
            )}
          </div>
        )}
        {!!info?.amount && (
          <h1 className="ModalSuccess-monto">
            <CustomSlot
              value={Number(info?.amount).toLocaleString("es-ES", {
                style: "decimal",
                maximumFractionDigits: 20,
              })}
              startValueOnce
              direction="bottom-up"
            />
          </h1>
        )}
        {!!info?.message && (
          <span className="ModalSuccess-sub">{info?.message}</span>
        )}
        {!!info?.extra && info?.extra}
        {!!info?.tx && (
          <a
            className="ModalSuccess-tx underline"
            href={info?.tx}
            target="_blank"
            rel="noreferrer"
          >
            {t("r2r-a-39")}
          </a>
        )}
      </div>
    </Modal>
  );
};

export default ModalSuccess;
